<template>
  <div>
    <v-row v-if="!showSuccess">
      <v-col col="12" class="tw-py-0">
        <h3
          class="tw-text-left tw-font-figtree tw-text-22 tw-text-dark-green tw-font-semibold"
        >
          Account Deletion
        </h3>
        <p class="tw-text-left tw-text-light-grey tw-mb-11">
          To delete your account, please send us a message requesting deletion
          and reason for deletion.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="!showSuccess">
      <v-col>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="save">
            <validation-provider v-slot="{ errors }" rules="required|max:500">
              <j-textarea
                v-model="report"
                outlined
                :error-messages="errors"
                background-color="#fafafa"
                label="Message*"
              />
            </validation-provider>
          </v-form>
          <span class="tw-hidden">{{ invalid }}</span>
        </validation-observer>
      </v-col>
    </v-row>
    <v-row v-if="showSuccess">
      <h3
        class="tw-text-center tw-font-figtree tw-text-22 tw-text-dark-green tw-font-semibold tw-w-full tw-mb-2"
      >
        Thank you!
      </h3>
      <p class="tw-text-center tw-text-light-grey tw-mb-11">
        We’ll send you an email confirming when your account has been deleted.
        If you have any questions, please reach us at <br /><a
          class="tw-font-bold tw-text-light-grey"
          target="_blank"
          href="mailto:'support@letsjetty.com'"
          >support@letsjetty.com</a
        >.
      </p>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DeleteAccount",
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  components: {},
  data() {
    return {
      report: "",
      showSuccess: false
    };
  },
  watch: {
    report(newValue) {
      if (newValue.length === 0) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      }
    }
  },
  methods: {
    save() {
      let data = {
        category: "DELETEACCOUNT",
        userId: this.user.id,
        userFullName: this.user.fullName,
        userEmail: this.user.email,
        text: this.report
      };
      this.$store
        .dispatch("support/create", [data])
        .then(() => {
          this.showSuccess = true;
          this.$store.commit("meta/setBottomNavigation", {
            hidden: true
          });
        })
        .catch((err) => {
          console.error("error", err);
          this.$store.dispatch("meta/showGlobalAlert", {
            type: "error",
            text: "Error sending delete account report. Please try again.",
            timeout: 2000
          });
        });
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Delete Account",
      bgColor: "#E6FFA1",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: true,
      text: "Submit",
      isDisabled: true,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
  },
  beforeDestroy() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Profile",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/setBottomNavigation", {
      hidden: false
    });
  }
};
</script>
